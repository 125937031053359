import React from "react";
import lo1 from "../Assets/about/about-1.jpg";
import lo2 from "../Assets/about/about-2.jpg";

import { Container } from "../component/Core";
import { base } from "../theme";
import { ThemeProvider } from "styled-components";
import { Block2Col, Block4Col, BlockImg } from "../component/Block";

function About() {

  return (
    <ThemeProvider theme={base}>
      <Container width={[1]} display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" overflow="hidden">
        
        <Block2Col pb={[4]} size={[3]} gap={[1]} 
          col1a={'At Noname, we craft brands that make a statement. By merging the timeless precision of Bauhaus with bold strategy, we create brands that connect deeply with modern audiences and leave a lasting impression.'}
        />

        <Block2Col pb={[4]} size={[2]} gap={[3]} line={"1px #d5d5d5 solid"}
          col2a={"We believe in keeping things straightforward. By prioritizing simplicity, we start with a clear and structured process. Taking the time to understand your business and its purpose ensures that our work is grounded in genuine insight."}
          />

        <Block4Col pb={[1]} size={[2]} 
          col1a={"Clients"}
        />

        <Block4Col pb={[4]} size={[2]} gap={[1]} line={"1px #d5d5d5 solid"}
          col1a={"Elephant"}
          col1b={'Huge Inc.'}
          col1c={'Hyundai'}
          col2a={"Microsoft"}
          col2b={"Apple Music"}
          col2c={"Figs"}
          col3a={"Nike"}
          col3b={"Joy&Peace"}
          col3c={"Treedom"}
          col4a={"VW"}
          col4b={"Innocell"}
          col4c={"Canoo"}
        />

      


        <BlockImg image={lo1} />
        <Block2Col pb={[4]} size={[2]} gap={[1]} 
          col1a={'Tsz Ho Ip'}
          col2a={"My expertise extends beyond aesthetics to the creation of robust design systems that seamlessly integrate across various media. Whether it's a rapid branding initiative or a deeply emotive storytelling campaign, I possess the versatility to tailor my approach to meet the unique needs of each project."}
        />
        <BlockImg image={lo2} />
        <Block2Col pb={[4]} size={[2]} gap={[1]} 
          col1a={'Michelle Han'}
          col2a={"Michelle's expertise lies in crafting immersive brand experiences that seamlessly connect digital and physical interactions. Her bespoke approach ensures that every solution is meticulously tailored to align with business objectives, delivering impactful results across all dimensions of the brand experience."}
        />







      </Container>
    </ThemeProvider>
  );
}

export default About;
