import React from "react";
import { BlockThumb, BlockThumbFull } from "../component/Block";
import logo from "../Assets/logo/Llogo.jpg";
import nike from "../Assets/nike/LNike.jpg";
import inno from "../Assets/inn/LInno.jpg";
import shop from "../Assets/shopify/LShop.jpg";
import figs from "../Assets/figs/LFigs.jpg";
import CanooB from "../Assets/CanooB/LCanooB.jpg";
import CanooW from "../Assets/CanooW/LCanooW.jpg";
import Process from "../Assets/Process/LProcess.jpg";
import treedom from "../Assets/treedom/LTree.jpg";
import hyundai from "../Assets/hyundai/LHyundai.jpg";
import CanooDS from "../Assets/CanooDS/LCanooDS.jpg";
import CanooHMI from "../Assets/CanooHMI/LCanooHMI.jpg";
import salt from "../Assets/salt/Lsalt.jpg";
import { Container } from "../component/Core";


function Home() {
  BlockThumb.defaultProps = {
    width: [1 / 2, 1 / 2, 1 / 2, 1 / 2],
    height: ["240px","320px","440px","600px"],
  };
  BlockThumbFull.defaultProps = {
    width: [1 / 2, 1 / 2, 1 / 2, 1 / 2],
  };
  return (
    <Container width={[1]} display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
      <BlockThumb img={CanooB} to="/work/CanooB" title="Canoo, Brand"/>
      <BlockThumb img={CanooDS} to="/work/CanooDS" title="Canoo, Design system" />
      <BlockThumb img={CanooW} to="/work/CanooW" title="Canoo, Web" />
      <BlockThumb img={CanooHMI} to="/work/CanooHMI" title="Canoo, Infotainment" />
      <BlockThumb img={inno} to="/work/inno" title="Innocell, App" />
      <BlockThumb img={nike} to="/work/nike" title="nike, Art Direction" />
      <BlockThumb img={figs} to="/work/figs" title="Figs" />
      <BlockThumb img={treedom} to="/work/treedom" title="treedom" />
      <BlockThumb img={hyundai} to="/work/hyundai" title="hyundai" />
      <BlockThumb img={salt} to="/work/salt" title="salt" />
      <BlockThumb img={shop} to="/work/shopify" title="shopify" width={[1/2,1/2,1/2,1/2]}/>
      <BlockThumb img={logo} to="/logo" title="Logos" width={[1/2,1/2,1/2,1/2]}/>

      

    </Container>
  );
}

export default Home;
